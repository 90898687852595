@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.btn {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(0 90 255 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 90 255 / var(--tw-text-opacity));
  background-color: #0000;
  border-radius: 44px;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 2.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 90 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn {
  &.btn-inverse {
    --tw-bg-opacity: 1;
    background-color: rgb(0 90 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    border-style: none;
  }

  &.btn-inverse:hover {
    --tw-bg-opacity: .75;
  }
}

.burger {
  width: 30px;
  height: 20px;
  transition-duration: .5s;
  position: relative;

  & .icon-left-top {
    content: "";
    width: 15px;
    height: 2px;
    transition-duration: .5s;
    position: absolute;
    top: 0;
    left: 0;
  }

  & .icon-left-middle {
    width: 15px;
    height: 2px;
    transition-duration: .5s;
    position: absolute;
    top: 9px;
    left: 0;
  }

  & .icon-left-bottom {
    width: 15px;
    height: 2px;
    transition-duration: .5s;
    position: absolute;
    top: 18px;
    left: 0;
  }

  & .icon-right-top {
    width: 15px;
    height: 2px;
    transition-duration: .5s;
    position: absolute;
    top: 0;
    left: 15px;
  }

  & .icon-right-middle {
    width: 15px;
    height: 2px;
    transition-duration: .5s;
    position: absolute;
    top: 9px;
    left: 15px;
  }

  & .icon-right-bottom {
    width: 15px;
    height: 2px;
    transition-duration: .5s;
    position: absolute;
    top: 18px;
    left: 15px;
  }
}

header {
  backdrop-filter: blur(10px);
}

.home header {
  transition-property: background-color, color;
  transition-duration: .25s;

  & path {
    transition-property: fill;
    transition-duration: .25s;
  }
}

.header-light {
  color: #005aff;
  background-color: #ffffffbf;

  & path {
    fill: #005aff;
  }

  & .mobile-header .burger-button .burger > div {
    background-color: #005aff;
  }
}

.header-dark {
  color: #c8d2e5;
  background-color: #0009;

  & path {
    fill: #c8d2e5;
  }

  & .mobile-header .burger-button .burger > div {
    background-color: #c8d2e5;
  }
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-40 {
  top: 10rem;
}

.z-10 {
  z-index: 10;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[101\] {
  z-index: 101;
}

.z-\[102\] {
  z-index: 102;
}

.z-\[15\] {
  z-index: 15;
}

.z-\[20\] {
  z-index: 20;
}

.z-\[80\] {
  z-index: 80;
}

.z-\[90\] {
  z-index: 90;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-12 {
  height: 3rem;
}

.h-19 {
  height: 4.75rem;
}

.h-48 {
  height: 12rem;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[130px\] {
  height: 130px;
}

.h-\[64px\] {
  height: 64px;
}

.h-\[84px\] {
  height: 84px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-full {
  max-height: 100%;
}

.min-h-48 {
  min-height: 12rem;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.min-h-\[280px\] {
  min-height: 280px;
}

.min-h-\[500px\] {
  min-height: 500px;
}

.w-24 {
  width: 6rem;
}

.w-\[64px\] {
  width: 64px;
}

.w-full {
  width: 100%;
}

.min-w-20 {
  min-width: 5rem;
}

.max-w-20 {
  max-width: 5rem;
}

.max-w-40 {
  max-width: 10rem;
}

.max-w-48 {
  max-width: 12rem;
}

.max-w-60 {
  max-width: 15rem;
}

.max-w-\[1140px\] {
  max-width: 1140px;
}

.max-w-\[184px\] {
  max-width: 184px;
}

.max-w-\[580px\] {
  max-width: 580px;
}

.max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[650px\] {
  max-width: 650px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[730px\] {
  max-width: 730px;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-\[928px\] {
  max-width: 928px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-lg {
  max-width: 1040px;
}

.max-w-screen-mg {
  max-width: 920px;
}

.flex-1 {
  flex: 1;
}

.-translate-x-\[1px\] {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[2px\] {
  --tw-translate-x: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[3px\] {
  --tw-translate-x: -3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[100vh\] {
  --tw-translate-y: -100vh;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[1px\] {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[2px\] {
  --tw-translate-y: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[3px\] {
  --tw-translate-y: -3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[1px\] {
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[2px\] {
  --tw-translate-x: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[3px\] {
  --tw-translate-x: 3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[1px\] {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[2px\] {
  --tw-translate-y: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[3px\] {
  --tw-translate-y: 3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-0 {
  gap: 0;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}

.text-nowrap {
  text-wrap: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[44px\] {
  border-radius: 44px;
}

.rounded-xl {
  border-radius: .75rem;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-active {
  --tw-border-opacity: 1;
  border-color: rgb(0 90 255 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/20 {
  background-color: #0003;
}

.bg-ghost {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.bg-successMessageBack {
  --tw-bg-opacity: 1;
  background-color: rgb(233 252 224 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-270 {
  background-image: linear-gradient(270deg, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-\[\#0147BF\] {
  --tw-gradient-from: #0147bf var(--tw-gradient-from-position);
  --tw-gradient-to: #0147bf00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#728DD1\] {
  --tw-gradient-from: #728dd1 var(--tw-gradient-from-position);
  --tw-gradient-to: #728dd100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#005DFF\] {
  --tw-gradient-to: #005dff var(--tw-gradient-to-position);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-clip-text {
  background-clip: text;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-whiteLight {
  fill: #c8d2e5;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-14 {
  padding: 3.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-20 {
  padding: 5rem;
}

.p-28 {
  padding: 7rem;
}

.p-32 {
  padding: 8rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-64 {
  padding: 16rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-60 {
  padding-top: 15rem;
}

.pt-64 {
  padding-top: 16rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-hero {
  line-height: 150%;
}

.text-active {
  --tw-text-opacity: 1;
  color: rgb(0 90 255 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-successMessage {
  --tw-text-opacity: 1;
  color: rgb(72 153 59 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-whiteLight {
  --tw-text-opacity: 1;
  color: rgb(200 210 229 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-70 {
  opacity: .7;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-\[0ms\] {
  transition-delay: 0s;
}

.delay-\[100ms\] {
  transition-delay: .1s;
}

.delay-\[120ms\] {
  transition-delay: .12s;
}

.delay-\[140ms\] {
  transition-delay: .14s;
}

.delay-\[160ms\] {
  transition-delay: .16s;
}

.delay-\[180ms\] {
  transition-delay: .18s;
}

.delay-\[20ms\] {
  transition-delay: 20ms;
}

.delay-\[40ms\] {
  transition-delay: 40ms;
}

.delay-\[60ms\] {
  transition-delay: 60ms;
}

.delay-\[80ms\] {
  transition-delay: 80ms;
}

.text-shadow {
  text-shadow: 1px 1px 10px #000000b3;
}

* {
  box-sizing: border-box;
}

.solution, .company, .careers, .contact {
  & main {
    margin-top: 4.75rem;
  }
}

.input {
  --tw-bg-opacity: 1;
  background-color: rgb(245 246 248 / var(--tw-bg-opacity));
  outline-offset: 2px;
  border-width: 1px;
  border-color: #0000;
  border-radius: .5rem;
  outline: 2px solid #0000;
  padding: .75rem 1rem;
}

.input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 90 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(235 242 255 / var(--tw-bg-opacity));
}

#company-map-canvas {
  padding-bottom: 52.0833%;
  position: relative;

  & svg {
    position: absolute;
  }

  & .pin {
    fill: #005aff;
    stroke: #005aff;
    animation: 1s infinite pins-pulse;
  }

  & .label {
    fill: #005aff;
  }

  & .label-bg {
    fill: #fff;
    stroke: #000;
    stroke-width: 3px;
    stroke-opacity: .05;
  }
}

@keyframes pins-pulse {
  0% {
    stroke-width: 0;
    stroke-opacity: .8;
  }

  100% {
    stroke-width: 30px;
    stroke-opacity: 0;
  }
}

#solution-radar-chart-canvas {
  --transition-duration: .6s;
  --series-fill-opacity-focus: .6;
  --series-fill-opacity: .3;
  --label-font-size-focus: 1.1em;
  --label-font-size: 1em;

  & svg {
    max-height: max(100vh - 28rem - 300px, 500px);
  }

  & .series {
    fill-opacity: var(--series-fill-opacity-focus);
    transition-property: fill, fill-opacity;
    transition-duration: var(--transition-duration);
  }

  & .label {
    font-size: var(--label-font-size-focus);
    transition: fill, font-size;
    transition-duration: var(--transition-duration);
  }

  & .series-label {
    opacity: 0;
    transition-property: opacity;
    transition-duration: var(--transition-duration);

    & rect {
      transition-property: fill;
      transition-duration: var(--transition-duration);
    }
  }

  & .state-radar-strengths .series-lidar, & .state-radar-strengths .series-camera, & .state-lidar-strengths .series-radar, & .state-lidar-strengths .series-camera, & .state-camera-strengths .series-lidar, & .state-camera-strengths .series-radar, & .state-lir-boost-imaging .series-lidar, & .state-lir-boost-imaging .series-camera, & .state-lir-with-camera .series-lidar {
    fill-opacity: var(--series-fill-opacity);
    fill: gray !important;
  }

  & .state-radar-strengths .label-winner-lidar, & .state-radar-strengths .label-winner-camera, & .state-lidar-strengths .label-winner-radar, & .state-lidar-strengths .label-winner-camera, & .state-camera-strengths .label-winner-lidar, & .state-camera-strengths .label-winner-radar, & .state-lir-boost-imaging .label-winner-lidar, & .state-lir-boost-imaging .label-winner-camera, & .state-lir-with-camera .label {
    font-size: var(--label-font-size);
    fill: #000 !important;
  }

  & .state-radar-strengths .series-label-radar, & .state-lidar-strengths .series-label-lidar, & .state-camera-strengths .series-label-camera, & .state-lir-boost-imaging .series-label-radar, & .state-lir-with-camera .series-label-radar {
    opacity: 1;
  }

  & .state-lir-boost-imaging, & .state-lir-with-camera {
    & .series-radar, & .series-label-radar rect {
      fill: #005aff !important;
    }
  }

  & .state-lir-boost-imaging {
    & .label-winner-radar {
      fill: #005aff !important;
    }
  }

  & .state-lir-with-camera {
    & .series-lidar {
      fill-opacity: 0;
      transition: fill-opacity 3s;
    }
  }
}

#solution-radar-chart-message div {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  padding: 1.5rem;
  transition-property: opacity;
  transition-duration: 1s;
}

@media (width >= 1280px) {
  .xl\:header-dark {
    color: #c8d2e5;
    background-color: #0009;
  }
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-85:hover {
  opacity: .85;
}

.peer:checked ~ .peer-checked\:fixed {
  position: fixed;
}

.peer:checked ~ .peer-checked\:hidden {
  display: none;
}

.peer:checked ~ .peer-checked\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media not all and (width >= 1280px) {
  .max-xl\:mt-19 {
    margin-top: 4.75rem;
  }
}

@media not all and (width >= 1040px) {
  .max-lg\:hidden {
    display: none;
  }

  .max-lg\:flex-col {
    flex-direction: column;
  }
}

@media not all and (width >= 768px) {
  .max-md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .max-md\:hidden {
    display: none;
  }
}

@media (width >= 640px) {
  .sm\:hidden {
    display: none;
  }
}

@media (width >= 768px) {
  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-\[600px\] {
    width: 600px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 1040px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

@media (width >= 1280px) {
  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:bottom-0 {
    bottom: 0;
  }

  .xl\:left-0 {
    left: 0;
  }

  .xl\:left-1\/2 {
    left: 50%;
  }

  .xl\:top-28 {
    top: 7rem;
  }

  .xl\:top-32 {
    top: 8rem;
  }

  .xl\:h-\[100vh\] {
    height: 100vh;
  }

  .xl\:min-h-\[100vh\] {
    min-height: 100vh;
  }

  .xl\:w-\[50vw\] {
    width: 50vw;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:flex-\[1_1_0\] {
    flex: 1 1 0;
  }

  .xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:items-stretch {
    align-items: stretch;
  }

  .xl\:bg-black\/20 {
    background-color: #0003;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:opacity-0 {
    opacity: 0;
  }

  .xl\:transition-\[flex-basis\] {
    transition-property: flex-basis;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-\[top\] {
    transition-property: top;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-\[top_opacity\] {
    transition-property: top opacity;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:duration-300 {
    transition-duration: .3s;
  }

  .xl\:hover\:flex-\[1_1_40\%\]:hover {
    flex: 40%;
  }

  .group\/category:hover .xl\:group-hover\/category\:top-0 {
    top: 0;
  }

  .group\/category:hover .xl\:group-hover\/category\:bg-black\/0 {
    background-color: #0000;
  }

  .group\/category:hover .xl\:group-hover\/category\:opacity-100 {
    opacity: 1;
  }
}

.\[\&\>div\]\:opacity-0 > div {
  opacity: 0;
}

.\[\&\>div\]\:transition > div {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.peer:checked ~ .peer-checked\:\[\&\>div\]\:opacity-100 > div {
  opacity: 1;
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-bottom\]\:-translate-y-\[3px\] .mobile-header > .burger-button > .burger > .icon-left-bottom {
  --tw-translate-y: -3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-bottom\]\:translate-x-\[2px\] .mobile-header > .burger-button > .burger > .icon-left-bottom {
  --tw-translate-x: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-bottom\]\:-rotate-45 .mobile-header > .burger-button > .burger > .icon-left-bottom {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-bottom\]\:scale-x-110 .mobile-header > .burger-button > .burger > .icon-left-bottom {
  --tw-scale-x: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-bottom\]\:scale-y-150 .mobile-header > .burger-button > .burger > .icon-left-bottom {
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-middle\]\:bg-transparent .mobile-header > .burger-button > .burger > .icon-left-middle {
  background-color: #0000;
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-top\]\:translate-x-\[2px\] .mobile-header > .burger-button > .burger > .icon-left-top {
  --tw-translate-x: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-top\]\:translate-y-\[3px\] .mobile-header > .burger-button > .burger > .icon-left-top {
  --tw-translate-y: 3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-top\]\:rotate-45 .mobile-header > .burger-button > .burger > .icon-left-top {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-top\]\:scale-x-110 .mobile-header > .burger-button > .burger > .icon-left-top {
  --tw-scale-x: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-left-top\]\:scale-y-150 .mobile-header > .burger-button > .burger > .icon-left-top {
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-bottom\]\:-translate-x-\[2px\] .mobile-header > .burger-button > .burger > .icon-right-bottom {
  --tw-translate-x: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-bottom\]\:-translate-y-\[3px\] .mobile-header > .burger-button > .burger > .icon-right-bottom {
  --tw-translate-y: -3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-bottom\]\:rotate-45 .mobile-header > .burger-button > .burger > .icon-right-bottom {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-bottom\]\:scale-x-110 .mobile-header > .burger-button > .burger > .icon-right-bottom {
  --tw-scale-x: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-bottom\]\:scale-y-150 .mobile-header > .burger-button > .burger > .icon-right-bottom {
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-middle\]\:bg-transparent .mobile-header > .burger-button > .burger > .icon-right-middle {
  background-color: #0000;
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-top\]\:-translate-x-\[2px\] .mobile-header > .burger-button > .burger > .icon-right-top {
  --tw-translate-x: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-top\]\:translate-y-\[3px\] .mobile-header > .burger-button > .burger > .icon-right-top {
  --tw-translate-y: 3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-top\]\:-rotate-45 .mobile-header > .burger-button > .burger > .icon-right-top {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-top\]\:scale-x-110 .mobile-header > .burger-button > .burger > .icon-right-top {
  --tw-scale-x: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>_\.icon-right-top\]\:scale-y-150 .mobile-header > .burger-button > .burger > .icon-right-top {
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>div\]\:bg-white .mobile-header > .burger-button > .burger > div {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.burger-button\>_\.burger\>div\]\:transition .mobile-header > .burger-button > .burger > div {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.peer:checked ~ .peer-checked\:\[\&_\.mobile-header\>_\.logo\>a\>svg\>path\]\:fill-whiteLight .mobile-header > .logo > a > svg > path {
  fill: #c8d2e5;
}

.\[\&_em\]\:not-italic em {
  font-style: normal;
}

.\[\&_em\]\:text-active em {
  --tw-text-opacity: 1;
  color: rgb(0 90 255 / var(--tw-text-opacity));
}

.\[\&_li\]\:text-center li {
  text-align: center;
}

.\[\&_li\]\:text-white li {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\[\&_li\]\:text-shadow li {
  text-shadow: 1px 1px 10px #000000b3;
}

@media (width >= 1280px) {
  .xl\:\[\&_li\]\:mt-3 li {
    margin-top: .75rem;
  }

  .xl\:\[\&_li\]\:text-xl li {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:\[\&_path\]\:fill-whiteLight path {
    fill: #c8d2e5;
  }
}

.\[\&_svg\]\:w-full svg {
  width: 100%;
}

.\[\&_svg_path\]\:fill-whiteLight svg path {
  fill: #c8d2e5;
}
/*# sourceMappingURL=index.0143c524.css.map */
