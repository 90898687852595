/* TODO: preload fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

@layer components {
    /* Button */
    .btn {
        @apply
        transition-colors
        duration-150
        mx-auto
        h-12
        px-10
        py-4
        bg-transparent
        border
        border-active
        text-active
        rounded-[44px]
        justify-center
        items-center
        gap-2
        inline-flex
        hover:bg-active
        hover:text-white;

        &.btn-inverse {
            @apply
            border-none
            bg-active
            text-white
            hover:bg-opacity-75;
        }
    }

    /* Mobile menu burger */
    .burger {
        position: relative;
        width: 30px;
        height: 20px;
        transition-duration: 0.5s;

        .icon-left-top {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 2px;
            content: "";
            top: 0;
            left: 0;
        }

        .icon-left-middle {
            transition-duration: 0.5s;
            position: absolute;
            height: 2px;
            width: 15px;
            top: 9px;
            left: 0;
        }

        .icon-left-bottom {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 2px;
            top: 18px;
            left: 0;
        }

        .icon-right-top {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 2px;
            top: 0;
            left: 15px;
        }

        .icon-right-middle {
            transition-duration: 0.5s;
            position: absolute;
            height: 2px;
            width: 15px;
            top: 9px;
            left: 15px;
        }

        .icon-right-bottom {
            transition-duration: 0.5s;
            position: absolute;
            width: 15px;
            height: 2px;
            top: 18px;
            left: 15px;
        }
    }

    /* Header dark/light themes */
    header {
        backdrop-filter: blur(10px);
    }

    .home header {
        transition-property: background-color, color;
        transition-duration: 250ms;

        & path {
            transition-property: fill;
            transition-duration: 250ms;
        }
    }

    .header-light {
        background-color: rgba(255, 255, 255, 0.75);
        color: theme('colors.active');

        & path {
            fill: theme('colors.active');
        }

        .mobile-header .burger-button .burger > div {
            background-color: theme('colors.active');
        }
    }

    .header-dark {
        background-color: rgba(0, 0, 0, 0.6);
        color: theme('colors.whiteLight');

        & path {
            fill: theme('colors.whiteLight');
        }

        .mobile-header .burger-button .burger > div {
            background-color: theme('colors.whiteLight');
        }
    }
}

@layer utilities {
    .text-shadow {
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.7);
    }
}

/* Layout for pages without hero unit */
.solution, .company, .careers, .contact {
    & main {
        @apply mt-19;
    }
}

/* Form fields */
.input {
    @apply
    px-4
    py-3
    rounded-lg
    border
    border-transparent
    outline-none
    bg-inputBack
    focus:bg-inputBackActive
    focus:border-active;
}

/* Company location map */
#company-map-canvas {
    position: relative;
    padding-bottom: calc(100% * 500.0 / 960.0);

    & svg {
        position: absolute;
    }

    .pin {
        fill: theme('colors.active');
        stroke: theme('colors.active');
        animation: 1s infinite pins-pulse;
    }

    .label {
        fill: theme('colors.active');
    }

    .label-bg {
        fill: white;
        stroke: black;
        stroke-width: 3px;
        stroke-opacity: 0.05;
    }
}

@keyframes pins-pulse {
    0% {
        stroke-width: 0;
        stroke-opacity: 0.8;
    }

    100% {
        stroke-width: 30px;
        stroke-opacity: 0;
    }
}

/* Radar chart */
#solution-radar-chart-canvas {
    & svg {
        max-height: max(calc(100vh - 28rem - 300px), 500px);
    }

    --transition-duration: 600ms;

    /* Series areas */
    --series-fill-opacity-focus: 0.6;
    --series-fill-opacity: 0.3;
    .series {
        fill-opacity: var(--series-fill-opacity-focus);
        transition-property: fill, fill-opacity;
        transition-duration: var(--transition-duration);
    }

    /* Axis labels */
    --label-font-size-focus: 1.1em;
    --label-font-size: 1em;
    .label {
        font-size: var(--label-font-size-focus);
        transition: fill, font-size;
        transition-duration: var(--transition-duration);
    }

    /* Series labels */
    .series-label {
        opacity: 0;
        transition-property: opacity;
        transition-duration: var(--transition-duration);

        /* Backgrounds */
        & rect {
            transition-property: fill;
            transition-duration: var(--transition-duration);
        }
    }

    /* Grayscale un-focused series areas and axis labels */
    .state-radar-strengths .series-lidar,
    .state-radar-strengths .series-camera,
    .state-lidar-strengths .series-radar,
    .state-lidar-strengths .series-camera,
    .state-camera-strengths .series-lidar,
    .state-camera-strengths .series-radar,
    .state-lir-boost-imaging .series-lidar,
    .state-lir-boost-imaging .series-camera,
    .state-lir-with-camera .series-lidar {
        fill: gray !important;
        fill-opacity: var(--series-fill-opacity);
    }

    .state-radar-strengths .label-winner-lidar,
    .state-radar-strengths .label-winner-camera,
    .state-lidar-strengths .label-winner-radar,
    .state-lidar-strengths .label-winner-camera,
    .state-camera-strengths .label-winner-lidar,
    .state-camera-strengths .label-winner-radar,
    .state-lir-boost-imaging .label-winner-lidar,
    .state-lir-boost-imaging .label-winner-camera,
    .state-lir-with-camera .label {
        fill: black !important;
        font-size: var(--label-font-size);
    }

    /* Show series labels on focused series */
    .state-radar-strengths .series-label-radar,
    .state-lidar-strengths .series-label-lidar,
    .state-camera-strengths .series-label-camera,
    .state-lir-boost-imaging .series-label-radar,
    .state-lir-with-camera .series-label-radar {
        opacity: 1;
    }

    /* In states where we highlight LIR, color it with our blue */
    .state-lir-boost-imaging, .state-lir-with-camera {
        .series-radar, .series-label-radar rect {
            fill: theme('colors.active') !important;
        }
    }
    .state-lir-boost-imaging {
        .label-winner-radar {
            fill: theme('colors.active') !important;
        }
    }

    /* In "sensor fusion" state, hide LIDAR series slowly */
    .state-lir-with-camera {
        .series-lidar {
            transition: fill-opacity 3s;
            fill-opacity: 0;
        }
    }
}

#solution-radar-chart-message div {
    transition-property: opacity;
    transition-duration: 1s;

    @apply p-6 text-gray-400;
}